.mainholder {
  padding: 6px;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;

  .content {
    border: 1px solid #111;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    text-align: center;
    color: #111;
    overflow: auto;

    .title {
      display: inline-block;
      background-color: #fff;
      color: #111;
      margin: 12px 0;
      padding: 6px 24px;

      .titletop {
        font-size: 1.5rem;
        text-transform: uppercase;
        font-weight: 500;
      }
      .titlebottom {
        font-size: 1rem;
        color: #333;
      }
    }
  }
}
