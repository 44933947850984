.questionform {
  margin: 12px 0;

  .inputrow {
    margin: 12px auto;
    width: 80%;
    //overflow: hidden;
    text-align: left;

    .inputname {
      display: inline-block;
      color: #fff;
      background-color: #111;
      padding: 2px 8px;
    }

    input[type=text], textarea {
      display: block;
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      border: 1px solid #111;
      padding: 12px 10px;
      font-size: 1rem;
      box-sizing: border-box;
      transition: transform 0.2s;
    }
    input:focus, textarea:focus {
      outline: none;
      transform: scale(1.04);
    }
    textarea:invalid {
      border: 1px solid #770a0a;
    }

    button {
      width: 100%;
      text-transform: uppercase;
      font-size: 1.4rem;
      padding: 6px 12px;
      cursor: pointer;
      background-color: #fff;
      color: #111;
      border: 1px solid #111;
      transition: 0.1s;
    }
    button:hover {
      background-color: #111;
      color: #fff;
    }
    button:focus {
      outline: none;
      transform: scale(1.04);
    }

    .checkrow {
      display: flex;
      align-items: center;
      margin: 12px 0;
      cursor: pointer;

      input {
        position: absolute;
        height: 0;
        width: 0;
      }

      .checkmark {
        position: relative;
        flex-shrink: 0;
        height: 20px;
        width: 20px;
        background-color: #111;
        margin-right: 10px;
        border: 1px solid #fff;
      }
      &:hover .checkmark {
        background-color: #444;
        border: 1px solid #111;
      }
      input:focus ~ .checkmark {
        background-color: #444;
      }

      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        left: 7px;
        top: 4px;
        width: 3px;
        height: 8px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
      input:checked ~ .checkmark:after {
        display: block;
      }
    }
  }
}

.basicpopupholder {
  .tickbox {
    margin: 24px 0;
  }

  .userkeybox {
    margin: 24px 0;

    .userkeytext {
      margin-bottom: 6px;
    }
  }

  .confirmexpand {
    .expandedcontent {
      transform: scaleY(0);
      transform-origin: top;
      overflow: hidden;
      transition: transform 0.4s;
    }
  }
}