$firstTickDuration: 0.4s;
$thickness: 12px;

.animatedtick {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transform: rotate(-45deg);

  .tickline1 {
    position: relative;
    height: $thickness*2+2;
    width: $thickness;
  }
  .tickline1::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: $thickness;
    background-color: #111;
    animation-name: tickanim1;
    animation-duration: $firstTickDuration;
    animation-fill-mode: forwards
  }

  .tickline2 {
    position: relative;
    height: $thickness;
    width: $thickness*4;
    margin-left: -$thickness;
  }
  .tickline2::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    height: $thickness;
    width: 100%;
    background-color: #111;
    animation-name: tickanim2;
    animation-duration: $firstTickDuration*3.25;
  }
}

@keyframes tickanim1 {
  from {
    height: 0%;
  }
  to {
    height: 100%
  }
}
@keyframes tickanim2 {
  0% {
    width: 0%;
  }
  20% {
    width: 0%;
  }
  100% {
    width: 100%
  }
}