.basicpopupholder {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.3);

  .popup {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    color: #111;
    padding: 24px;
    box-shadow: 0 14px 30px -10px black;

    input {
      display: block;
      margin-left: auto;
      margin-right: auto;
      font-size: 1.2rem;
      text-align: center;
      border: 1px solid #888;
      padding: 6px 4px;
      transition: transform 0.1s;
    }
    input:focus {
      outline: none;
      transform: scale(1.04);
    }

    button {
      display: block;
      margin: 0 auto;
      font-size: 1rem;
      padding: 6px 6px;
      border: 1px solid #111;
      background-color: transparent;
      cursor: pointer;
      transition: padding 0.1s;
    }
    button:hover {
      padding: 6px 12px;
    }
    button:focus {
      outline: none;
      background-color: #111;
      color: #fff;
    }
  }
}