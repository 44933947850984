.questionpage {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .topheader {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #111;

    .headbar {
      flex: 1;
    }

    .sidenav {
      background-color: #111;
      color: #fff;
      .navoption {
        background-color: transparent;
        color: inherit;
        height: 44px;
        width: 44px;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.6rem;
        font-weight: bold;
        cursor: pointer;
        transform: rotate(180deg);
        transition: transform 0.4s;
      }
      .navoption:hover {
        transform: rotate(540deg);
      }
      .navoption:focus {
        outline: none;
      }
    }
  }

  .messagelog {
    height: 100%;
    overflow: auto;

    .messagerow {
      margin: 8px;

      .senderdetails, .messagetime {
        font-size: 0.8rem;
        margin-bottom: 4px;
        margin: 0px 8px;
        color: #555;
      }

      .message {
        display: inline-block;
        max-width: 70%;
        padding: 8px;
        word-break: break-all;
        text-align: left;
        overflow: hidden;
      }

      &.from-asker {
        text-align: right;

        .message {
          border: 1px solid #111;
          border-radius: 0 6px 0 6px;
        }
      }
      &.from-replier {
        text-align: left;

        .message {
          background-color: #111;
          color: #fff;
          border-radius: 6px 0 6px 0;
        }
      }

      &.message-input {
        .message {
          padding: 0;

          textarea {
            border: 0;
            font-size: 1rem;
            padding: 8px;
            min-width: 40vw;
            resize: vertical;
          }
          textarea:focus {
            outline: none;
          }
        }
        
        button {
          display: block;
          background-color: #444;
          color: #fff;
          font-size: 1rem;
          border: 0;
          padding: 6px 12px;
          cursor: pointer;
          transition: background-color 0.2s;
        }
        button:hover {
          background-color: #111;
        }
        button:focus {
          outline: none;
        }
      }
      &.from-replier {
        .message {
          textarea {
            background-color: #111;
            color: #fff;
          }
        }
        
        button {
          border-radius: 0 6px 0 6px;
        }
      }
      &.from-asker {
        button {
          margin-left: auto;
          border-radius: 6px 0 6px 0;
        }
      }
    }
  }
}

.basicpopupholder {
  .statusmessage {
    &.error {
      color: red;
    }
  }

  input {
    margin: 12px auto;
  }

  .buttonrow {
    display: flex;

    button {
      flex: 1;
      margin: 0 10px;
    }
  }
}